.Chat {
    height: 100%;
    width: 100%;
}

.Chat-header {
    height: 56px;
    display: flex;
}

.Chat-chat-id {
    margin-left: 8px;
}

.Chat-logout-btn {
    margin-left: auto;
    margin-right: 8px;
    align-self: center;
}

.Chat-container {
    height: calc(100% - 56px);
}
